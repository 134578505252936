import React from 'react';

import SEO from '../components/SEO';
import Layout from '../components/Layout';

const Page = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-xl font-light">Page Not Found</h1>
      <h2 className="font-medium text-sm">Sorry for the inconvinience</h2>
    </div>
  </Layout>
);

export default Page;
